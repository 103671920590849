import { Injectable } from '@angular/core';
import posthog from 'posthog-js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor() { }

  public initAnalytics() {
    this.initPosthog();
  }

  public identify(userId: string, userDetails: any) {
    posthog.people.set({ email: userDetails.email });
  }

  /**
   * @description Log an app event
   * @param eventName - name of the event to log
   * @param params - key/value of properties(max 25 per event)
   */
  public logEvent(eventName: string, params: any) {
    posthog.capture(eventName, params);
  }

  public checkPosthogExperiment(name: string): string | boolean {
    // posthog.feature_flags.override({ price_plan: 'control' });
    // posthog.reloadFeatureFlags();
    const experiment = posthog.getFeatureFlag(name);
    return experiment;
  }

  public checkPosthogFeatureFlag(featureFlag: string) {
    if (posthog.isFeatureEnabled(featureFlag)) {
      return true;
    }
    return false;
  }

  private initPosthog() {
    posthog.init(
      environment.posthog.key,
      {
        api_host: 'https://app.posthog.com',
        autocapture: false,
        session_recording: {
          maskAllInputs: false,
          maskInputOptions: {
            password: true,
          },
          inlineStylesheet: false
        }
      }
    );
  }
}
