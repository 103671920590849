<div class="footer">
  <div class="wrap-shortcuts" *ngIf="showNavLinks">
    <a routerDirection="root" [routerLink]="programsService.url('')" *ngIf="showHome">
      <ion-text [color]="color" class="text-sm"><p>Home</p></ion-text>
    </a>
    <a routerDirection="root" [routerLink]="programsService.url('/utah-ruc-2023/privacypolicy')">
      <ion-text [color]="color"><p class="text-sm">Privacy policy</p> </ion-text>
    </a>
    <a routerDirection="root" [routerLink]="programsService.url('/utah-ruc-2023/tandc')">
      <ion-text [color]="color"><p class="text-sm">Terms and conditions</p></ion-text>
    </a>
  </div>
  <div id="wrap-branding">
    <ion-text color="medium">
      <p class="text-xs bold-500">© {{ now.getFullYear() }} ClearRoad, Inc</p>
    </ion-text>
    <ion-text color="medium">
      <p class="text-xs">29 Norman Avenue, Brooklyn, NY 11222</p>
    </ion-text>
  </div>
</div>
