import { Injectable, Provider } from '@angular/core';
import { ProgramsService } from 'src/app/programs/programs.service';

export const timezoneProviderFactory = async (programsService: ProgramsService) => {
  const currentProgram = await programsService.get();
  return currentProgram.timezone || 'UTC';
};

export const TIMEZONE_PROVIDER: Provider = {
  provide: 'TIMEZONE',
  useFactory: async (programsService: ProgramsService) => await timezoneProviderFactory(programsService),
  deps: [ProgramsService],
};

@Injectable({
  providedIn: 'root',
})
export class TimezoneProvider {
  constructor() {}
}
