import { IEnv } from './environment.interface';

export const environment: IEnv = {
  production: true,
  googleAnalytics: {
    enabled: true,
    key: 'UA-131116096-3'
  },
  api: {
    baseUrl: 'https://api.udotrucpilot.org/cam'
  },
  authApi: {
    accessToken: 'CH6DrZrtGqmUsUDRodUAvrEVuAaYqMJZC5ULk8zNvLNs',
    baseUrl: 'https://api.udotrucpilot.org/auth'
  },
  odometerApi: {
    baseUrl: 'https://api.udotrucpilot.org/odometer'
  },
  vinApi: {
    accessToken: '4zKPyBR1KFsujggaueURbH8esWrGzywaGNkCxMJaN6Lc',
    baseUrl: 'https://api.udotrucpilot.org/vin'
  },
  hereApi: {
    apikey: 'kUV5r6EEBOTENgM7ivCXuLIuAA0VqJU7wI3t4zevwlY',
    id: '1jNke1nVayWVMmjyoLqh',
    code: '54vvRc_q778iJXd9znBqiw'
  },
  stripeApi: {
    key: 'pk_test_j9SG60XDcwCfL1IMUFGu6my4001UEaNiMC'
  },
  sentryApi: {
    dsn: 'https://49d5d845b7a24f91aa0c22f15623f810@o373258.ingest.sentry.io/5569369',
    release: 'cam-app@b76583ec9ffcef2a9e18db992a4f35295a27c926',
    environment: 'staging'
  },
  posthog: {
    key: 'phc_bGTxQSTpgrs4cVsbYo6FMmtr3yVtU8V1IzrnSs76PFo'
  },
  vinCheckFile:{
    path: '/lab/assets/vin-check.json'
  },
  networks: ['URUC']
};
