<ion-app>
  <ion-split-pane contentId="main-content">
    <ion-menu type="overlay" contentId="main-content" menuId="main">
      <ion-header class="ion-no-border">
        <div id="header">
          <img class="logo" src="assets/img/logo.svg" />
          <ion-text color="dark">
            <h5 class="bold-600">{{ user?.name }}</h5>
          </ion-text>
          <ion-text color="medium">
            <p id="label-email" class="text-sm">{{ user?.email }}</p>
          </ion-text>
          <ion-text color="danger" *ngIf="impersonatedBy">
            <p class="text-xs ion-no-margin">
              Impersonated by admin: <br />
              <span class="bold-500">{{ impersonatedBy.email }}</span>
            </p>
          </ion-text>

          <ion-button class="ion-margin-top" color="danger" fill="clear" (click)="backToAdmin()" *ngIf="isImpersonated">
            Back to Admin
          </ion-button>
        </div>
      </ion-header>
      <ion-content>
        <div id="horizontal-line"></div>
        <ion-list>
          <ion-menu-toggle auto-hide="false" *ngIf="programsService.key">
            <ion-item
              detail="false"
              lines="none"
              routerDirection="root"
              [routerLink]="programsService.url('dashboard')"
              routerLinkActive="selected">
              <ion-icon slot="start" name="home"></ion-icon>
              <ion-label>
                <span i18n="@@programs.show.title">Dashboard</span>
              </ion-label>
            </ion-item>
            <ion-item
              detail="false"
              lines="none"
              routerDirection="root"
              [routerLink]="programsService.url('activity')"
              routerLinkActive="selected">
              <ion-icon slot="start" name="today"></ion-icon>
              <ion-label>
                <span i18n="@@vehicle-trips.list.title">Activity</span>
              </ion-label>
            </ion-item>
            <ion-item
              detail="false"
              lines="none"
              routerDirection="root"
              [routerLink]="programsService.url('vehicles')"
              routerLinkActive="selected">
              <ion-icon slot="start" name="car"></ion-icon>
              <ion-label>
                <span i18n="@@vehicles.list.title">Vehicles</span>
              </ion-label>
            </ion-item>
            <ion-item
              detail="false"
              lines="none"
              routerDirection="root"
              [routerLink]="programsService.url('wallet')"
              routerLinkActive="selected">
              <ion-icon slot="start" name="wallet"></ion-icon>
              <ion-label>
                <span i18n="@@users.profile.title">Wallet/Statements</span>
              </ion-label>
            </ion-item>
            <ion-item
              detail="false"
              lines="none"
              routerDirection="root"
              [routerLink]="programsService.url('users/account')"
              routerLinkActive="selected">
              <ion-icon slot="start" name="settings"></ion-icon>
              <ion-label>
                <span i18n="@@users.profile.title">Account</span>
              </ion-label>
            </ion-item>
          </ion-menu-toggle>
          <ion-menu-toggle auto-hide="false" *ngIf="user?.admin && platform.is('desktop')">
            <ion-item detail="false" lines="none" [href]="baseUrl + '/admin/'" target="_blank">
              <ion-icon slot="start" name="construct"></ion-icon>
              <ion-label>
                <span i18n="@@admin">Admin</span>
              </ion-label>
            </ion-item>
            <ion-item
              detail="false"
              lines="none"
              routerDirection="root"
              [routerLink]="programsService.url('users')"
              routerLinkActive="selected">
              <ion-icon slot="start" name="people"></ion-icon>
              <ion-label>
                <span i18n="@@users.list.title">Users</span>
              </ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
      </ion-content>
      <ion-footer no-border *ngIf="user">
        <ion-list class="ion-no-margin">
          <ion-menu-toggle auto-hide="false"> </ion-menu-toggle>

          <ion-item class="ion-padding-horizontal" button detail="false" lines="none" (click)="logout()">
            <ion-icon slot="start" name="log-out"></ion-icon>
            <ion-label>
              <span i18n="@@logout">Sign Out</span>
            </ion-label>
          </ion-item>
        </ion-list>
      </ion-footer>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
