import { Component } from '@angular/core';
import { Platform, MenuController } from '@ionic/angular';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { User } from '@clearroadlab/cam';

declare let ga;

import { environment } from '../environments/environment';
import { ProgramsService } from './programs/programs.service';
import { UsersService } from './users/users.service';
import { developerBaseUrl } from './utils';
import { SwUpdateService } from './shared/services/sw-update.service';
import { ThemeService } from './shared/services/theme.service';
import { Storage } from '@ionic/storage';
import { AnalyticsService } from './shared/services/analytics.service';

const waitForGA = (callback: () => void) => {
  if ('ga' in window) {
    return callback();
  }
  setTimeout(() => waitForGA(callback), 100);
};

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  public user: User;
  public baseUrl = developerBaseUrl;
  public installPrompt = null;
  public isImpersonated = false;
  public impersonatedBy: User;

  constructor(
    angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
    public platform: Platform,
    private menuCtrl: MenuController,
    private swUpdateService: SwUpdateService,
    public programsService: ProgramsService,
    public themeService: ThemeService,
    public usersService: UsersService,
    private storage: Storage,
    private analyticsService: AnalyticsService
  ) {
    this.initializeApp();
    waitForGA(() => {
      ga('create', environment.googleAnalytics.key, environment.googleAnalytics.enabled ? 'auto' : 'none');
      angulartics2GoogleAnalytics.startTracking();
    });
    this.usersService.user$.subscribe(user => this.updateUser(user));
    this.usersService.isImpersonatedSession.subscribe(res => this.isImpersonated = res);
    this.analyticsService.initAnalytics();
  }

  private async initializeApp() {
    await this.themeService.init();
    await this.platform.ready();
    await this.swUpdateService.checkForUpdate();
    this.getInstallPrompt();
    this.menuCtrl.swipeGesture(this.user ? true : false);
    this.impersonatedBy = await this.storage.get('ap');
    if (this.impersonatedBy) {
      this.usersService.isImpersonatedSession.next(true);
    }
  }

  private async updateUser(user: User) {
    this.user = user;
    return this.menuCtrl.enable(!!user, 'main');
  }

  logout() {
    return this.usersService.signOut();
  }

  async backToAdmin() {
    this.impersonatedBy.token = await this.storage.get('at');
    await this.storage.remove('ap');
    await this.storage.remove('at');
    await this.usersService.updateCurrentUser(this.impersonatedBy);
    this.usersService.isImpersonatedSession.next(false);
    setTimeout(() => window.location.reload());
  }

  getInstallPrompt() {
    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.installPrompt = e;
      // Update UI notify the user they can install the PWA
      this.listenToUserAction();
      // Optionally, send analytics event that PWA install promo was shown.
    });
  }

  // listen to install button clic
  listenToUserAction() {
    const installBtn = document.querySelector('.install-btn');
    installBtn.addEventListener('click', this.presentAddToHome);
  }

  // present install prompt to user
  presentAddToHome() {
    this.installPrompt.prompt();  // Wait for the user to respond to the prompt
    this.installPrompt.userChoice
      .then(choice => {
        if (choice.outcome === 'accepted') {
          // console.log('User accepted');
        } else {
          // console.log('User dismissed');
        }
      });
  }
}
