import { Component, Input } from '@angular/core';

import { ProgramsService } from '../../programs/programs.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  @Input() color = 'primary';
  @Input() showHome = true;
  @Input() showNavLinks = false;

  public now = new Date();

  constructor(
    public programsService: ProgramsService
  ) { }
}
