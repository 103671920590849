import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { ProgramsGuardService } from './programs/programs-guard.service';
import { LoggedInGuardService } from './users/logged-in-guard.service';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';

const routes: Routes = [
  // make sure non-logged in users can access default version without programs
  {
    path: '',
    loadChildren: () => import('./programs/programs.module').then(m => m.ProgramsModule),
    canActivate: [ProgramsGuardService]
  },
  {
    path: ':program-key',
    loadChildren: () => import('./programs/programs.module').then(m => m.ProgramsModule),
    canActivate: [ProgramsGuardService]
  },
  {
    path: 'not-found',
    component: NotFoundPageComponent
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'not-found'
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: 'legacy'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
