import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';

const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
const storageKey = 'cam_dm';

export interface ITheme {
  name: string;
  class: string;
  color: string;
}

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  public themes: ITheme[] = [
    { name: 'default', color: '#3D77C3', class: 'default' },
    { name: 'red', color: '#d72e14', class: 'red-theme' },
    { name: 'green', color: '#469b72', class: 'green-theme' },
    { name: 'gray', color: '#9b9b9b', class: 'gray-theme' },
    { name: 'blue', color: '#004e9a', class: 'blue-theme' },
    { name: 'orange', color: '#d5651a', class: 'orange-theme' }
  ];
  public darkThemeEnabled = false;
  public $currentTheme = new BehaviorSubject<ITheme>(this.themes[0]);
  public renderer: Renderer2;

  constructor(
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document,
    private storage: Storage,
    private loadingCtrl: LoadingController
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
    this.storage.get('theme').then(theme => {
      if (theme) {
        this.$currentTheme.next(theme);
      }
    });
  }

  async setTheme(theme: ITheme) {
    const prevTheme = await this.storage.get('theme');
    if (prevTheme) {
      this.renderer.removeClass(this.document.body, prevTheme.class);
    }
    this.$currentTheme.next(theme);
    this.storage.set('theme', theme);
    this.renderer.addClass(this.document.body, theme.class);
    // loading.dismiss();
  }

  public async init() {
    const theme = await this.storage.get('theme');
    this.setTheme(theme || this.themes[0]);
    this.$currentTheme.next(theme || this.themes[0]);

    const isEnabled = await this.storage.get(storageKey);
    if (isEnabled === true || prefersDark.matches) {
      this.updateDarkMode(isEnabled);
    }
    else if (isEnabled === null) {
      prefersDark.addListener(({ matches }) => this.updateDarkMode(matches));
    }
  }

  public async updateDarkMode(enabled) {
    this.darkThemeEnabled = enabled;
    await this.storage.set(storageKey, enabled);
    document.body.classList.toggle('dark', enabled);
  }
}
